import Masonry from "react-responsive-masonry";
import { HiOutlineEmojiSad } from "react-icons/hi";

interface Props {
  images: any[];
  isAdmin: boolean;
  loading?: boolean;
}

const ImagesMasonry: React.FC<Props> = ({
  images = [],
  isAdmin = false,
  loading,
}) => {
  if (loading) {
    return (
      <Masonry columnsCount={2} gutter="5px">
        <div className="flex justify-center items-center">
          <div className="w-80 h-72 rounded-lg bg-gray-400 opacity-20 animate-pulse"></div>
        </div>
        <div className="flex justify-center items-center">
          <div className="w-80 h-96 rounded-lg bg-gray-400 opacity-20 animate-pulse"></div>
        </div>
        <div className="flex justify-center items-center">
          <div className="w-80 h-80 rounded-lg bg-gray-400 opacity-20 animate-pulse"></div>
        </div>
        <div className="flex justify-center items-center">
          <div className="w-80 h-96 rounded-lg bg-gray-400 opacity-20 animate-pulse"></div>
        </div>
      </Masonry>
    );
  }

  if (!images.length && !loading) {
    return (
      <div className="flex justify-center items-center flex-col">
        <HiOutlineEmojiSad
          fontSize="300px"
          color="#aeaeae"
          style={{ marginTop: "27px" }}
        />
        <p className="font-semibold">Aún no hay fotos. ¡Sé el primero!</p>
      </div>
    );
  }

  return (
    <Masonry columnsCount={2} gutter="5px">
      {images.map((image) => (
        <img
          key={image.id}
          src={image.url}
          className="rounded-lg"
          loading="lazy"
        />
      ))}
    </Masonry>
  );
};

export default ImagesMasonry;
