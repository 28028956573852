import { createClient, User } from "@supabase/supabase-js";

export const supabase = createClient(
  import.meta.env.VITE_SUPABASE_PUBLIC_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY
);

export const getEventsByUser = async () => {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  const { data, error } = await supabase
    .from("tblEvent")
    .select(
      `*,
    tblPlans (
      name,
      maxPhotos
    ),
    transImageEvent ( id )`
    )
    .eq("user_id", user?.id);

  if (error) throw new Error(error.message);

  return data;
};

export const getEventById = async (eventId: string) => {
  const { data, error } = await supabase
    .from("tblEvent")
    .select(
      `*,
    tblPlans (
      name,
      maxPhotos
    )`
    )
    .eq("id", eventId);
  if (error) throw new Error(error.message);
  return data[0];
};

export const getPlans = async () => {
  const { data, error } = await supabase
    .from("tblPlans")
    .select("*")
    .order("id", { ascending: true });

  if (error) throw new Error(error.message);

  return data;
};
