import { createBrowserRouter, redirect } from "react-router-dom";
import AuthPage from "./pages/auth";
import AppPage from "./pages/app";
import NewEventPage from "./pages/app/NewEvent";
import AppEventPage from "./pages/app/EventPage";
import EventPage from "./pages/event/EventPage";
import { getEventById, getEventsByUser, getPlans, supabase } from "./supabase";
import HomePage from "./pages/home";
import ErrorPage from "./pages/ErrorPage";

const getUser = async () => {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  return user;
};

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <HomePage />,
      errorElement: <ErrorPage />,
      loader: async () => {
        const user = await getUser();
        const plans = await getPlans();
        return { user, plans };
      },
    },
    {
      path: "/auth",
      element: <AuthPage />,
      errorElement: <ErrorPage />,
      loader: async () => {
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (user) return redirect("/app");
        return null;
      },
    },
    {
      path: ":id",
      element: <EventPage />,
      errorElement: <ErrorPage />,
      loader: async ({ params }) => {
        const event = await getEventById(params.id!);
        const user = await getUser();
        if (!event) return redirect("/app");
        return { event, plan: event.tblPlans, user };
      },
    },
    {
      path: "/app",
      element: <AppPage />,
      loader: async () => {
        const user = await getUser();
        if (!user) return redirect("/auth");
        return {};
      },
    },
    {
      path: "/app/new",
      element: <NewEventPage />,
      loader: async () => {
        const user = await getUser();
        if (!user) return redirect("/auth");
        const plans = await getPlans();
        return redirect("/app");
        // return { plans, user };
      },
    },
    {
      path: "/app/event/:id",
      element: <AppEventPage />,
      errorElement: <ErrorPage />,
      loader: async ({ params }) => {
        const user = await getUser();
        if (!user) return redirect("/auth");
        const events = await getEventsByUser();
        const event = events.find((e: any) => e.id === params.id);
        if (!event) return redirect("/app");
        const { data: images, error } = await supabase
          .from("transImageEvent")
          .select("*")
          .eq("event_id", event.id)
          .is("deleted_at", null)
          .order("created_at", { ascending: false });
        if (error) {
          throw new Error(error.message);
        }
        return { event, images, user };
      },
    },
  ],
  { basename: "/" }
);

export default router;
