import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Wallet } from "@mercadopago/sdk-react";
import AppLayout from "../../layouts/AppLayout";

const NewEventPage = () => {
  const { plans, user } = useLoaderData() as any;
  const [planSelectedId, setPlanSelectedId] = useState(2);

  const onSubmit = async () => {
    const planSelected = plans.find((plan: any) => plan.id === planSelectedId);
    return new Promise((res, rej) => {
      fetch(`${import.meta.env.VITE_API_URL}/checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          planName: planSelected.name,
          planPrice: planSelected.price,
          planId: planSelected.id,
          userId: user.id,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          res(response.id);
        })
        .catch(() => {
          rej();
        });
    });
  };

  return (
    <AppLayout pageTitle="Creando evento" hideNavbar>
      <h1 className="text-center">Elige un plan para tu evento</h1>
      <p className="text-center">Precio final, sin impuestos.</p>
      <Wallet
        initialization={{
          redirectMode: "modal",
        }}
        onSubmit={onSubmit}
      />
    </AppLayout>
  );
};

export default NewEventPage;
