import { useLoaderData } from "react-router-dom";
import { Header, HowWorks } from "../../components/Home";

const HomePage = () => {
  const { user, plans } = useLoaderData() as any;
  return (
    <main>
      <Header isLogged={Boolean(user?.id)} />
      <HowWorks />
    </main>
  );
};

export default HomePage;
