import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabase";
import LogoNavbar from "../LogoNavbar";
import { Button } from "../ui/button";
import { useToast } from "@/hooks/use-toast";

const Navbar = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast({
        title: "Error",
        description: "Algo salió mal, por favor intenta más tarde.",
        variant: "destructive",
      });
      return null;
    }
    return navigate("/auth", { replace: true });
  };

  return (
    <div className="flex justify-between items-center">
      <LogoNavbar />
      <Button onClick={signOut}>Cerrar sesión</Button>
    </div>
  );
};

export default Navbar;
