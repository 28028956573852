import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import useQR from "@/hooks/useQR";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { LuClipboardCopy } from "react-icons/lu";
import { useToast } from "@/hooks/use-toast";

interface Props {
  open?: boolean;
  onClose: () => void;
  eventId: string;
}

const ShareModal: React.FC<Props> = ({ open, onClose, eventId }) => {
  const { toast } = useToast();
  const qr = useQR({ eventId }).qr;
  const eventUrl = `${import.meta.env.VITE_FRONT_URL}/${eventId}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(eventUrl).then(() => {
      toast({
        description: "¡Copiado!",
      });
    });
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Compartir</DialogTitle>
          <DialogDescription>
            <img src={qr} alt="qr" />
            <div className="flex items-center w-full mt-4 gap-2">
              <Input value={eventUrl} readOnly />
              <Button variant="outline" onClick={handleCopy}>
                <LuClipboardCopy />
              </Button>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ShareModal;
