import { useState } from "react";
import { Helmet } from "react-helmet";
import LogoNavbar from "../../components/LogoNavbar";
import { onSignInWithOtp } from "./signInWithOtp";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";

const AuthPage = () => {
  const { toast } = useToast();
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onSendEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const response = await onSignInWithOtp(
      email,
      import.meta.env.VITE_FRONT_URL
    );
    setLoading(false);
    if (response.error) {
      toast({
        title: response.error,
        description: response.errorMessage,
        variant: "destructive",
      });
    } else {
      toast({
        title: "Email enviado",
        description: "Revisa tu casilla de correo para continuar.",
      });
      setSent(true);
    }
  };

  return (
    <main>
      <Helmet>
        <title>{import.meta.env.VITE_APP_NAME} - Iniciando Sesión</title>
      </Helmet>
      <div className="h-screen w-full flex flex-col items-center justify-center">
        <LogoNavbar />
        <Card>
          <form onSubmit={(event) => onSendEmail(event)}>
            <CardHeader>
              <CardTitle>Iniciar Sesión</CardTitle>
            </CardHeader>
            <CardContent>
              <Input
                placeholder="ejemplo@email.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </CardContent>
            <CardFooter className="flex justify-end">
              <Button type="submit" disabled={loading || sent}>
                {sent ? "Enviado" : "Enviar"}
              </Button>
            </CardFooter>
          </form>
        </Card>
        <small className="mt-4 text-xs font-normal text-center">
          Te enviaremos un link a tu email para que puedas ingresar.
          <br />
          Si no tienes cuenta, se te creará automáticamente.
        </small>
      </div>
    </main>
  );
};

export default AuthPage;
