import ToolButton from "../App/ToolsPanel/ToolButton";
import { LuShare2, LuRotateCw } from "react-icons/lu";
import ImageUploadButton from "../ImageUploadButton";

interface Props {
  eventId: string;
  onSuccess?: () => void;
  onReload: () => void;
  onOpenShare: () => void;
}

const BottomBar: React.FC<Props> = ({
  eventId,
  onSuccess,
  onReload,
  onOpenShare,
}) => {
  return (
    <div className="w-screen fixed left-0 bottom-3 flex items-center justify-center">
      <div className="flex m-auto bg-transparent gap-6 items-center justify-center rounded-3xl h-10">
        <ToolButton Icon={LuRotateCw} onClick={onReload} />
        <ImageUploadButton eventId={eventId} onSuccess={onSuccess} />
        <ToolButton Icon={LuShare2} onClick={onOpenShare} />
      </div>
    </div>
  );
};

export default BottomBar;
