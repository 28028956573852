import { useNavigate } from "react-router-dom";

const LogoNavbar = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex items-center gap-2 cursor-pointer"
      onClick={() => navigate("/")}
    >
      <h1 className="font-bold">{import.meta.env.VITE_APP_NAME}</h1>
    </div>
  );
};

export default LogoNavbar;
