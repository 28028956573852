/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import { HiCamera } from "react-icons/hi";
import { uploadImage } from "../utils";
import { useToast } from "@/hooks/use-toast";

interface Props {
  eventId: string;
  onSuccess?: () => void;
}

const ImageUploadButton: React.FC<Props> = ({ eventId, onSuccess }) => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const onUpload = () => {
    const element = document.getElementById("imageFile")! as HTMLInputElement;
    const file = element.files![0];
    setLoading(true);
    uploadImage(
      file,
      { eventId },
      () => {
        setLoading(false);
        onSuccess?.();
      },
      () => {
        setLoading(false);
        toast({
          title: "Error",
          description: "Algo salió mal, por favor intenta nuevamente.",
          variant: "destructive",
        });
      }
    );
  };

  return (
    <>
      <input
        id="imageFile"
        type="file"
        accept="image/*"
        onChange={onUpload}
        disabled={loading}
        capture
      />
      <label htmlFor="imageFile">
        <HiCamera />
      </label>
    </>
  );
};

export default ImageUploadButton;
