import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import LogoNavbar from "../LogoNavbar";
import { Button } from "../ui/button";

interface Props {
  isLogged: boolean;
}

const Header: React.FC<Props> = ({ isLogged }) => {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate("/app");
  };

  return (
    <div style={{ height: "100dvh" }}>
      <div className="items-center justify-between">
        <LogoNavbar />
        <Button onClick={goToDashboard}>
          {isLogged ? "Panel de control" : "Iniciar Sesión"}
        </Button>
      </div>
      <div className="h-screen flex flex-col gap-4">
        <img src="/mockup_homepage.png" />
        <h1 className="text-center">Recuerdos inolvidables en un sólo lugar</h1>
        <Button
          onClick={() => {
            if (isLogged) {
              navigate("/app/new");
            } else {
              navigate("/auth");
            }
          }}
        >
          Crear un evento
        </Button>
        <div className="flex items-center justify-center gap-2 mt-4 howitworks_text">
          <AiOutlineArrowDown />
          <p className="text-center text-xs">Conocé cómo funciona</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
