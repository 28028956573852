interface Props {
  Icon: any;
  onClick?: () => void;
}

const ToolButton: React.FC<Props> = ({ Icon, onClick }) => {
  return (
    <button
      className="flex flex-col items-center justify-center gap-2 w-12 "
      onClick={onClick}
    >
      <Icon className="text-sm text-zinc-100" />
    </button>
  );
};

export default ToolButton;
