import React from "react";
import Navbar from "../components/App/Navbar";
import { Helmet } from "react-helmet";

interface Props {
  children: React.ReactNode;
  hideNavbar?: boolean;
  pageTitle: string;
}

const AppLayout: React.FC<Props> = ({
  children,
  hideNavbar = false,
  pageTitle,
}) => {
  return (
    <main>
      <Helmet>
        <title>
          {import.meta.env.VITE_APP_NAME} - {pageTitle}
        </title>
      </Helmet>
      {!hideNavbar && <Navbar />}
      <div>{children}</div>
    </main>
  );
};

export default AppLayout;
