const HowWorks = () => {
  return (
    <>
      <h1>
        La app de
        <span style={{ color: "#805AD5" }}> fotos </span>
        para tus eventos
      </h1>
      <p className="mt-2">
        <b>Natpicfy </b>
        te provee una manera simple y rápida de que tus invitados suban sus
        fotos durante tu evento, permitiéndoles compartir y recoradar juntos los
        momentos más memorables. Ya sea una boda, un cumpleaños o cualquier otra
        ocasión especial,
        <b> Natpicfy </b>
        hace que compartir fotos sea fácil, divertido y al instante.
      </p>
    </>
  );
};

export default HowWorks;
