import { useNavigate } from "react-router-dom";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { LuPlusCircle } from "react-icons/lu";
import EventCard from "../../components/App/EventCard/EventCard";
import AppLayout from "../../layouts/AppLayout";
import { Button } from "@/components/ui/button";
import { EventCardSkeleton } from "@/components/App/EventCard";
import { useEffect, useState } from "react";
import { getEventsByUser } from "@/supabase";
import { Event } from "@/interfaces/event";
import useLoading from "@/hooks/useLoading";

const AppPage = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const { loading, startLoading, stopLoading } = useLoading(true);

  const getEvents = async () => {
    startLoading();
    const events = await getEventsByUser();
    stopLoading();
    setEvents(events || []);
  };

  useEffect(() => {
    getEvents();
  }, []);

  const navigate = useNavigate();

  return (
    <AppLayout pageTitle="Mis eventos">
      <div className="flex item-center justify-between mb-3">
        <h1 className="font-bold text-3xl">Mis eventos</h1>
        <Button onClick={() => navigate("/app/new")}>
          <div className="flex items-center justify-center gap-2">
            <LuPlusCircle />
            <>Crear</>
          </div>
        </Button>
      </div>
      {!events.length && !loading ? (
        <div className="flex flex-col items-center justify-center">
          <HiOutlineEmojiSad className="text-zinc-500 text-8xl mt-8" />
          <p className="text-zinc-500">
            Aún no tienes ningún evento. ¡Crea uno!
          </p>
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          {events.map((event: any) => (
            <EventCard
              key={event.id}
              name={event.name}
              date={new Date(event.created_at).toLocaleDateString()}
              plan={event.tblPlans.name}
              cantPhotos={event.transImageEvent.length}
              maxPhotos={event.tblPlans.maxPhotos}
              isActive={event.isActive}
              onClick={() => navigate(`/app/event/${event.id}`)}
            />
          ))}
        </div>
      )}
      {loading && (
        <div className="flex flex-col gap-3">
          <EventCardSkeleton />
          <EventCardSkeleton />
          <EventCardSkeleton />
        </div>
      )}
    </AppLayout>
  );
};

export default AppPage;
