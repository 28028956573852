import { Skeleton } from "@/components/ui/skeleton";

const EventCardSkeleton = () => {
  return (
    <div className="w-full border rounded-lg shadow-md p-2 space-y-3">
      <Skeleton className="h-5 w-3/4 bg-gray-200" />
      <Skeleton className="h-4 w-1/2 bg-gray-200" />
      <Skeleton className="h-3 w-1/3 bg-gray-200" />
    </div>
  );
};

export default EventCardSkeleton;
