import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLoaderData } from "react-router-dom";
import ImagesMasonry from "../../components/ImagesMasonry";
import BottomBar from "@/components/Event/BottomBar";
import { supabase } from "@/supabase";
import { useToast } from "@/hooks/use-toast";
import { ImageEvent } from "@/interfaces/imageEvent";
import useLoading from "@/hooks/useLoading";
import ShareModal from "@/components/Event/ShareModal";

const EventPage = () => {
  const { event } = useLoaderData() as any;
  const { toast } = useToast();
  const { loading, startLoading, stopLoading } = useLoading(true);
  const [scroll, setScroll] = useState(0);
  const [images, setImages] = useState<ImageEvent[]>([]);
  const [openShare, setOpenShare] = useState(false);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const getImages = async () => {
    startLoading();
    const { data: images, error } = await supabase
      .from("transImageEvent")
      .select("*")
      .eq("event_id", event.id)
      .is("deleted_at", null)
      .order("created_at", { ascending: false });

    if (error) {
      toast({
        title: "Error",
        description: "Algo salió mal, por favor intenta nuevamente.",
        variant: "destructive",
      });
    }

    setImages((images || []) as ImageEvent[]);
    stopLoading();
  };

  useEffect(() => {
    getImages();
  }, []);

  useEffect(() => {
    if (imageContainerRef.current) {
      imageContainerRef.current.addEventListener("scroll", () => {
        setScroll(imageContainerRef.current?.scrollTop || 0);
      });
    }
    () => {
      imageContainerRef.current?.removeEventListener("scroll", () => {});
    };
  }, [imageContainerRef]);

  const shareEvent = () => {
    navigator.share({
      title: import.meta.env.VITE_APP_NAME,
      text: `¡Estoy usando ${
        import.meta.env.VITE_APP_NAME
      } para compartir fotos en ${event.name}!`,
      url: window.location.href,
    });
  };

  const onToggleShare = () => {
    setOpenShare((prev) => !prev);
  };

  return (
    <>
      <Helmet>
        <title>{`${event.name} - ${import.meta.env.VITE_APP_NAME}`}</title>
      </Helmet>
      <div className="portrait-container">
        <img
          className=""
          style={{ height: `calc(300px - ${scroll}px)`, minHeight: "100px" }}
          src="https://oxmqsnygawhuthcgcscn.supabase.co/storage/v1/object/public/natpicfy/bb504c1d-1977-49cf-b7af-1673709ed204/images/98ad3ba9-44cb-4ebc-8fc7-677c7d143cc8-16930118563623890458684064380942.jpg"
          ref={imageRef}
          alt="portada"
        />
        <div className="portrait-container-info">
          <h1 className="font-semibold text-4xl mb-0 leading-3">
            {event.name}
          </h1>
          <small className="m-0 font-light text-sm">Memoroo</small>
        </div>
      </div>
      <div
        className={`p-2 overflow-${loading ? "hidden" : "auto"}`}
        style={{
          height: `calc(100dvh - ${
            ((imageRef.current?.height || 300) >= 300
              ? 300
              : imageRef.current?.height! || 0) + 70
          }px)`,
        }}
        ref={imageContainerRef}
      >
        <ImagesMasonry isAdmin={false} images={images} loading={loading} />
      </div>
      <BottomBar
        eventId={event.id}
        onReload={getImages}
        onOpenShare={onToggleShare}
      />
      <ShareModal open={openShare} onClose={onToggleShare} eventId={event.id} />
    </>
  );
};

export default EventPage;
