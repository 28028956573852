import "./index.css";
import "@fontsource/raleway/400.css";
import "@fontsource/open-sans/700.css";

import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { initMercadoPago } from "@mercadopago/sdk-react";
import * as Sentry from "@sentry/react";
import router from "./router";
import { ThemeProvider } from "./components/themeProvider";
import { Toaster } from "./components/ui/toaster";

initMercadoPago(import.meta.env.VITE_MP_PUBLIC_KEY, { locale: "es-AR" });

Sentry.init({
  dsn: "https://5c70a0c89fee43011551f7d073ec3604@o4508055631101952.ingest.us.sentry.io/4508055632216064",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ThemeProvider defaultTheme="dark" storageKey="ui-theme">
    <RouterProvider router={router} />
    <Toaster />
  </ThemeProvider>
);
