import { toDataURL } from "qrcode";
import { v4 as uuid } from "uuid";
import Compress from "compress.js";
import { supabase } from "../supabase";

export const arePropsEqual =
  (...props: any[]) =>
  (oldProps: any, newProps: any) => {
    return !props.some((prop) => oldProps[prop] !== newProps[prop]);
  };

export const getQRCode = (value: string) => {
  let qrValue;
  toDataURL(value, { width: 400, margin: 2 }, (err, url) => {
    if (err) {
      console.error(err);
      return;
    }
    qrValue = url;
  });
  return qrValue;
};

export const uploadImage = async (
  file: File,
  params: { eventId: string },
  onSuccess: () => void,
  onError: () => void
) => {
  const { eventId } = params;
  if (
    file.type !== "image/jpeg" &&
    file.type !== "image/jpg" &&
    file.type !== "image/png"
  ) {
    return;
  }

  const { data: event, error: errorGettingEvent } = await supabase
    .from("tblEvent")
    .select(
      `*,
    tblPlans (
      quality,
      maxPhotos
    ),
    transImageEvent ( id, deleted_at )
    `
    )
    .eq("id", eventId)
    .single();

  if (errorGettingEvent) {
    onError();
    return;
  }

  if (
    event.transImageEvent?.filter((img: any) => img.deleted_at === null)
      .length >= event.tblPlans.maxPhotos
  ) {
    return;
  }

  const compress = new Compress();
  const resizedImage = await compress.compress([file], {
    quality: event.tblPlans.quality,
  });
  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
  const fileName = `${uuid()}-${file.name}`;
  const filePath = `${eventId}/images/${fileName}`;
  const { error } = await supabase.storage
    .from("natpicfy")
    .upload(filePath, resizedFile, {
      cacheControl: "3600",
      upsert: false,
    });

  if (error) {
    onError();
    return;
  }
  const {
    data: { publicUrl },
  } = supabase.storage.from("natpicfy").getPublicUrl(filePath);

  const { error: errorSavingInTable } = await supabase
    .from("transImageEvent")
    .insert({ event_id: eventId, url: publicUrl });

  if (errorSavingInTable) {
    onError();
    return;
  }

  onSuccess();
};
