import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";
import AppLayout from "../../layouts/AppLayout";
import ImageUploadButton from "../../components/ImageUploadButton";
import ImagesMasonry from "../../components/ImagesMasonry";
import { ToolsPanel } from "@/components/App/ToolsPanel";
import { Button } from "@/components/ui/button";

const EventPage = () => {
  const { event, images, user } = useLoaderData() as any;
  const plan = event.tblPlans;
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  return (
    <AppLayout pageTitle={`Panel de control - ${event.name}`}>
      <Button onClick={() => navigate("/app")}>
        Volver al panel de control
      </Button>
      <h1 className="text-3xl font-semibold">{event.name}</h1>
      <div className="flex gap-2 mt-1">
        <small>Plan {event.tblPlans.name} -</small>
        <small>{event.isActive ? "Abierto" : "Cerrado"}</small>
      </div>
      {images.length < plan.maxPhotos && (
        <ImageUploadButton
          eventId={event.id}
          onSuccess={() => revalidator.revalidate()}
          style={{ marginTop: "10px" }}
        />
      )}

      <h3 className="text-md font-semibold">{`${images.length} / ${plan.maxPhotos} Fotos`}</h3>
      <ImagesMasonry images={images} isAdmin={event.user_id === user.id} />

      <ToolsPanel />
    </AppLayout>
  );
};

export default EventPage;
