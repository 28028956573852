import { Card, CardContent } from "../../ui/card";
import { LuCalendarDays } from "react-icons/lu";

interface Props {
  name: string;
  plan: string;
  date: string;
  isActive: boolean;
  cantPhotos: number;
  maxPhotos: number;
  onClick: () => void;
}

const EventCard: React.FC<Props> = ({
  name,
  plan,
  date,
  isActive,
  cantPhotos,
  maxPhotos,
  onClick,
}) => {
  return (
    <Card className="cursor-pointer hover:bg-zinc-50" onClick={onClick}>
      <CardContent className="p-4">
        <h1 className="font-semibold text-base">{name}</h1>
        <p className="text-base">
          {isActive ? "Activo" : "Inactivo"} - Plan {plan}
        </p>
        <div className="flex items-center gap-2 text-zinc-500">
          <LuCalendarDays />
          <small>Creado el {date}</small>
        </div>
      </CardContent>
    </Card>
  );
};

export default EventCard;
