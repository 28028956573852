import { supabase } from "../../supabase";

interface Response {
  error: string | null;
  errorMessage: string | null;
}

const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const onSignInWithOtp = async (
  email: string,
  redirectTo: string
): Promise<Response> => {
  if (!email || !emailReg.test(email)) {
    return {
      error: "Email inválido",
      errorMessage: "Por favor, ingresa un email válido.",
    };
  }

  const { error } = await supabase.auth.signInWithOtp({
    email,
    options: {
      emailRedirectTo: `${redirectTo}/app`,
    },
  });

  if (error) {
    return {
      error: "Error",
      errorMessage: "Algo salió mal, por favor intenta más tarde.",
    };
  }

  return { error: null, errorMessage: null };
};
