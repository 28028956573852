import { useNavigate } from "react-router-dom";
import LogoNavbar from "../components/LogoNavbar";
import { Button } from "@/components/ui/button";

const ErrorPage = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <main>
      <div className="m-auto flex items-center justify-center flex-col gap-3 h-screen">
        <h1 className="text-center text-7xl font-bold">Oops!</h1>
        <h2 className="text-center text-2xl font-semibold">
          Parece que algo salió mal
        </h2>
        <p className="text-center">
          Puede que haya ocurrido un error o la página a la que intentas
          ingresar no existe.
        </p>
        <Button onClick={goToHome}>Volver al inicio</Button>
        <LogoNavbar sx={{ mt: 10 }} />
      </div>
    </main>
  );
};

export default ErrorPage;
