import ToolButton from "./ToolButton";
import {
  LuQrCode,
  LuExternalLink,
  LuSettings,
  LuLock,
  LuLogOut,
} from "react-icons/lu";

const ToolsPanel = () => {
  return (
    <div className="flex gap-6 items-center overflow-auto w-full fixed left-0 bottom-0 p-2 border-t bg-white h-15 shadow-md">
      <ToolButton Icon={LuExternalLink} />
      <ToolButton Icon={LuQrCode} />
      <ToolButton Icon={LuLock} />
      <ToolButton Icon={LuSettings} />
      <ToolButton Icon={LuLogOut} />
    </div>
  );
};

export default ToolsPanel;
