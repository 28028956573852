import { useEffect, useState } from "react";
import { getQRCode } from "@/utils";
import html2canvas from "html2canvas";

interface Args {
  eventId: string;
}

const useQR = ({ eventId }: Args) => {
  const [qr, setQr] = useState();

  useEffect(() => {
    const qrValue = getQRCode(`${import.meta.env.VITE_FRONT_URL}/${eventId}`);
    setQr(qrValue);
  }, []);

  const onDownloadQR = () => {
    const container = document.getElementById("qr-container")!;
    html2canvas(container, {
      allowTaint: true,
      width: container?.offsetWidth,
      height: container?.offsetHeight,
    }).then((canvas) => {
      const link = document.createElement("a");
      link.download = "qr.png";
      link.href = canvas.toDataURL();
      link.target = "_blank";
      link.click();
    });
  };

  return { qr, onDownloadQR };
};

export default useQR;
